import styled, { css } from 'styled-components';
import { createBreakpoint } from '@styles';

export const OrgEndorsementItems = styled.ul`
  column-count: 5;
  column-gap: 40px;
  row-gap: 40px;
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 0px auto;

  li {
    display: flex;
    min-width: calc(50% - 20px);
    max-width: calc(50% - 20px);

    a svg {
      width: 23px;
      height: 21px;
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    li {
      display: flex;
      min-width: calc(33% - 27px);
      max-width: calc(33% - 27px);

      a svg {
        width: 23px;
        height: 21px;
      }
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    li {
      display: flex;
      min-width: calc(20% - 32px);
      max-width: calc(20% - 32px);

      a svg {
        width: 23px;
        height: 21px;
      }
    }
  `)}
`;

export const EndorsementTypeHeader2 = styled.h2`
  color: ${({ theme }) => theme.palette.primary};
  letter-spacing: 0.01em;
  line-height: 1.1;
  font-size: 32px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const ElectedEndorsementItems = styled.ul`
  column-count: 5;
  column-gap: 40px;
  row-gap: 40px;
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0px auto;
  li {
    display: flex;
    min-width: 100%;
    max-width: 100%;

    a svg {
      width: 23px;
      height: 21px;
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.mobileLarge, css`
    li {
      display: flex;
      min-width: calc(50% - 20px);
      max-width: calc(50% - 20px);

      a svg {
        width: 23px;
        height: 21px;
      }
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    li {
      display: flex;
      min-width: calc(33% - 27px);
      max-width: calc(33% - 27px);

      a svg {
        width: 23px;
        height: 21px;
      }
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    li {
      display: flex;
      min-width: calc(20% - 32px);
      max-width: calc(20% - 32px);

      a svg {
        width: 23px;
        height: 21px;
      }
    }
  `)}
`;

export const FeaturedEndorsementItems = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0px auto;

  &:first-of-type {
    margin-bottom: 80px;
  }
`;

export const EndorsementTypeWrapper = styled.section`
  max-width: ${({ theme }) => theme.layout.wideMaxWidth}px;
  margin: 0px auto 60px;
`;

export const Content = styled.div`
  padding: 0px 32px;
`;
