import React from 'react';
import { graphql } from 'gatsby';
import { Layout, PlanHero, Endorsement } from '@components';
import { Content, IntroWrapper, ContentGutter } from '@styles';
import { getKeyValue, compileRichText } from '@utils';
import { LIGHT } from '@utils/vars';
import {
  OrgEndorsementItems, EndorsementTypeWrapper, ElectedEndorsementItems,
  FeaturedEndorsementItems, EndorsementTypeHeader2,
} from './endorsements-landing.styles';

const EndorsementsSection = ({
  endorsements,
  allEndorsements,
  sectionTitle,
  type,
}) => (
  <React.Fragment>
    {(endorsements || (allEndorsements && allEndorsements.edges
    && allEndorsements.edges.length > 0)) && (
      <EndorsementTypeWrapper>
        <EndorsementTypeHeader2 textTheme="primary">{sectionTitle}</EndorsementTypeHeader2>
        {type === 'Organizations' ? (
          <OrgEndorsementItems>
            {endorsements && endorsements.map((item) => (
              <Endorsement {...item} />
            ))}
            {!endorsements && allEndorsements.edges.map((item) => {
              const endorsementProps = getKeyValue(item, 'node') || {};
              if (endorsementProps.name) {
                return (
                  <Endorsement {...endorsementProps} />
                );
              }
              return null;
            })}
          </OrgEndorsementItems>
        ) : (
          <ElectedEndorsementItems>
            {endorsements && endorsements.map((item) => (
              <Endorsement {...item} />
            ))}
            {!endorsements && allEndorsements.edges.map((item) => {
              const endorsementProps = getKeyValue(item, 'node') || {};
              if (endorsementProps.name) {
                return (
                  <Endorsement {...endorsementProps} />
                );
              }
              return null;
            })}
          </ElectedEndorsementItems>
        )}
      </EndorsementTypeWrapper>
    )}
  </React.Fragment>
);

const EndorsementsLandingTemplate = ({ location, data, pageContext }) => {
  const {
    site, page, globalSettings, allOrgEndorsements, allElectedEndorsements,
    allCommunityEndorsements,
  } = data;

  const { locale } = pageContext;
  const { title: siteTitle, social } = getKeyValue(site, 'siteMetadata') || {};
  const {
    pageTitle, contentEntry, languages, pageDescription, metaImage, pageUrl,
    disableDonateBar, disableAlertBar, disableSplash, doNotIndex,
  } = page || {};
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};
  const {
    intro, title, heroImage, featuredEndorsements, endorsementSectionOrdering,
    organizationEndorsementSectionTitle, organizationEndorsements,
    electionOfficialsEndorsementSectionTitle, electionOfficialsEndorsements,
    communityLeaderEndorsementSectionTitle, communityLeaderEndorsements,
  } = contentEntry || {};
  const {
    navigation, enableAlertBar, alertBarLink, alertBarText, footerDisclaimer,
    footerSecondaryLinks, footerPrimaryLinks, footerSocialLinks, footerCopyright, footerAddress,
    enableDonateBar, donationBar, donateBarDisclaimer, enableSplash, splash, footerForm,
    footerEmails, footerPhoneNumber, formLabelLanguageOverrides, footerDonateByMail,
  } = globalSettings || {};

  const footerProps = {
    footerCopyright,
    footerDisclaimer,
    footerSocialLinks,
    footerPrimaryLinks,
    footerSecondaryLinks,
    footerForm,
    footerAddress,
    footerEmails,
    footerPhoneNumber,
    formLabelLanguageOverrides,
    footerDonateByMail,
  };

  const donateBarProps = {
    ...donationBar,
    donateBarDisclaimer,
  };

  const alertBarProps = {
    alertBarLink,
    alertBarText,
  };

  const splashProps = {
    ...splash,
    donateBarDisclaimer,
    formLabelLanguageOverrides,
  };

  let sections = [];
  if (endorsementSectionOrdering) {
    endorsementSectionOrdering.forEach((section, i) => {
      if (section === 'Organizations') {
        sections[i] = {
          endorsements: organizationEndorsements,
          allEndorsements: allOrgEndorsements,
          sectionTitle: organizationEndorsementSectionTitle,
        };
      }
      if (section === 'Elected Officials') {
        sections[i] = {
          endorsements: electionOfficialsEndorsements,
          allEndorsements: allElectedEndorsements,
          sectionTitle: electionOfficialsEndorsementSectionTitle,
        };
      }
      if (section === 'Community Leaders') {
        sections[i] = {
          endorsements: communityLeaderEndorsements,
          allEndorsements: allCommunityEndorsements,
          sectionTitle: communityLeaderEndorsementSectionTitle,
        };
      }
    });
  } else {
    sections = [{
      endorsements: organizationEndorsements,
      allEndorsements: allOrgEndorsements,
      sectionTitle: organizationEndorsementSectionTitle,
    }, {
      sectionTitle: electionOfficialsEndorsementSectionTitle,
      electionEndorsements: electionOfficialsEndorsements,
      allEndorsements: allElectedEndorsements,
    }, {
      endorsements: communityLeaderEndorsements,
      allEndorsements: allCommunityEndorsements,
      sectionTitle: communityLeaderEndorsementSectionTitle,
    }];
  }

  return (
    <Layout
      location={location}
      navigation={navigation}
      languages={languages}
      footerProps={footerProps}
      donateBarProps={donateBarProps}
      alertBarProps={alertBarProps}
      enableAlertBar={enableAlertBar && !disableAlertBar}
      enableDonateBar={enableDonateBar && !disableDonateBar}
      enableSplash={enableSplash && !disableSplash}
      metaDescription={pageDescription}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${pageUrl}`}
      metaTitle={`${pageTitle} | ${siteTitle}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      splashProps={splashProps}
      locale={locale}
      navTheme={LIGHT}
      doNotIndex={doNotIndex}
      fullWidth
    >
      <PlanHero title={title} heroImage={heroImage} intro={intro} />
      <ContentGutter>
        <Content>
          <IntroWrapper>
            {intro && (!heroImage || !heroImage.fluid) && (
              compileRichText({ textTheme: 'primary', size: 'large', locale })(intro)
            )}
          </IntroWrapper>
          {featuredEndorsements && (
          <EndorsementTypeWrapper>
            <FeaturedEndorsementItems>
              {featuredEndorsements.map((featured) => (
                <Endorsement {...featured} featuredEndorsement />
              ))}
            </FeaturedEndorsementItems>
          </EndorsementTypeWrapper>
          )}
          {sections && sections.map((section) => (
            <EndorsementsSection {...section} />
          ))}
        </Content>
      </ContentGutter>
    </Layout>
  );
};

export default EndorsementsLandingTemplate;

export const endorsementsLandingPageQuery = graphql`
  query EndorsementsLandingBySlug($slug: String!, $locale: String!) {
    site {
      ...SiteMetadata
    }
    page: contentfulPage(pageUrl: { eq: $slug }) {
      ...PageMetadata
      contentEntry {
        ... on ContentfulNewsItemEndorsementsLanding {
          title
          heroImage {
            description
            id
            fluid(quality: 90, maxWidth: 700) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          intro {
            raw
          }
          endorsementSectionOrdering
          organizationEndorsementSectionTitle
          organizationEndorsements {
            id
            name
            link
            endorsementType
            description {
              raw
            }
            logoheadshot {
              description
              id
              fluid(quality: 90, maxWidth: 500) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          electionOfficialsEndorsementSectionTitle
          electionOfficialsEndorsements {
            id
            name
            link
            endorsementType
            description {
              raw
            }
            logoheadshot {
              description
              id
              fluid(quality: 90, maxWidth: 500) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          communityLeaderEndorsementSectionTitle
          communityLeaderEndorsements {
            id
            name
            link
            endorsementType
            description {
              raw
            }
            logoheadshot {
              description
              id
              fluid(quality: 90, maxWidth: 500) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          featuredEndorsements {
            endorsementType
            logoheadshot {
              description
              id
              fluid(quality: 90, maxWidth: 500) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            description {
              raw
            }
            name
          }
        }
      }
    }
    globalSettings: contentfulGlobalSettings(language: { eq: $locale }) {
      ...GlobalSettings
    }
    allElectedEndorsements: allContentfulEndorsement(filter: {endorsementType: {eq: "Elected Official"}}, sort: { fields: [name], order: DESC }) {
      edges {
        node {
          id
          name
          link
          endorsementType
          description {
            raw
          }
          logoheadshot {
            description
            id
            fluid(quality: 90, maxWidth: 500) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    allCommunityEndorsements: allContentfulEndorsement(filter: {endorsementType: {eq: "Community Leader"}}, sort: { fields: [name], order: DESC }) {
      edges {
        node {
          id
          name
          link
          endorsementType
          description {
            raw
          }
          logoheadshot {
            description
            id
            fluid(quality: 90, maxWidth: 500) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    allOrgEndorsements: allContentfulEndorsement(filter: {endorsementType: {eq: "Organization"}}, sort: { fields: [name], order: DESC }) {
      edges {
        node {
          id
          name
          link
          endorsementType
          description {
            raw
          }
          logoheadshot {
            description
            id
            fluid(quality: 90, maxWidth: 500) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
